import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface BroadcastViewStateType {
  ts: number;
  tsStr: string;
  youtube: number;
  chzzk: number;
  soop: number;
  twitch: number;
  youtubeRatio: number;
  chzzkRatio: number;
  soopRatio: number;
  twitchRatio: number;
}

const initialState: BroadcastViewStateType = {
  ts: 0,
  tsStr: '',
  youtube: 0,
  chzzk: 0,
  soop: 0,
  twitch: 0,
  youtubeRatio: 0.0,
  chzzkRatio: 0.0,
  soopRatio: 0.0,
  twitchRatio: 0.0,
};

export const broadcastViewSlice = createSlice({
  name: 'broadcastView',
  initialState,
  reducers: {
    setting: (state, action: PayloadAction<BroadcastViewStateType>) => {
      state.ts = action.payload.ts;
      state.tsStr = action.payload.tsStr;
      state.youtube = action.payload.youtube;
      state.chzzk = action.payload.chzzk;
      state.soop = action.payload.soop;
      state.twitch = action.payload.twitch;
      state.youtubeRatio = action.payload.youtubeRatio;
      state.chzzkRatio = action.payload.chzzkRatio;
      state.soopRatio = action.payload.soopRatio;
      state.twitchRatio = action.payload.twitchRatio;
    },
  },
});

export const { setting } = broadcastViewSlice.actions;
export default broadcastViewSlice.reducer;
