import ApexCharts from 'react-apexcharts';
import React from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { insertCommas } from 'utils/number-utils';
import { useTheme } from '@mui/material/styles';

// const ApexLineChart = ({ data }: {data: BroadcastViewListResponse;}): JSX.Element => {
const ApexLineChart = (): JSX.Element => {
  const [bvl] = useAppSelector((state) => [state.broadcastViewList]);

  const theme = useTheme();

  // console.log(bvl);
  const soop = bvl.broadcastViewListGet.map((item, i) => ({
    x: item.tsStr,
    y: item.soop,
  }));
  const chzzk = bvl.broadcastViewListGet.map((item, i) => ({
    x: item.tsStr,
    y: item.chzzk,
  }));
  const youtube = bvl.broadcastViewListGet.map((item, i) => ({
    x: item.tsStr,
    y: item.youtube,
  }));
  const twitch = bvl.broadcastViewListGet.map((item, i) => ({
    x: item.tsStr,
    y: item.twitch,
  }));

  return (
    <>
      <ApexCharts
        type="line"
        series={[
          {
            name: 'Afreeca',
            data: soop,
          },
          {
            name: 'Chzzk',
            data: chzzk,
          },
          {
            name: 'Youtube',
            data: youtube,
          },
          {
            name: 'Twitch',
            data: twitch,
          },
        ]}
        options={{
          chart: {
            height: 500,
            type: 'line',
            foreColor: theme.palette.text.primary,
            id: 'linechart-viewers',
            toolbar: {
              show: true,
              tools: {
                selection: true,
                download: false,
                zoom: true,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: true,
              },
            },
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            animations: {
              enabled: true,
              easing: 'linear',
              dynamicAnimation: {
                speed: 200,
              },
            },
          },
          dataLabels: {
            enabled: false,
            style: {
              fontSize: '7',
            },
          },
          tooltip: {
            theme: 'dark',
            x: {
              show: true,
              format: 'yyyy-MM-dd HH:mm:ss',
            },
          },
          stroke: {
            curve: 'straight', // curve?: 'smooth' | 'straight' | 'stepline' | 'monotoneCubic' | ('smooth' | 'straight' | 'stepline' | 'monotoneCubic')[]
            width: 3,
          },
          colors: ['#0546B0', '#18EA98', '#FF0000', '#5c15c5'],
          grid: {
            borderColor: '#e7e7e7',
            padding: {
              right: 30,
              left: 10,
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
            position: 'front',
            row: {
              //   colors: ['#f3f3f3', 'transparent'],
              opacity: 0.1,
            },
          },
          title: {
            style: {
              fontFamily: 'NanumSquareAcr',
            },
            text: '시청자 추이',
            align: 'center',
          },
          xaxis: {
            type: 'datetime',
            labels: {
              datetimeUTC: false,
              format: 'HH:mm:ss',
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            title: {
              text: '',
            },
            tooltip: {
              enabled: false,
            },
            labels: {
              style: {
                colors: '#000',
                fontFamily: 'NanumSquareAceb',
              },
              formatter: function (value) {
                return insertCommas(value);
              },
            },
          },
          markers: {
            size: 0,
          },
          legend: {
            show: false,
            position: 'top',
            horizontalAlign: 'center',
          },
        }}
      ></ApexCharts>
    </>
  );
};

export default ApexLineChart;
