import { Box, Grid, Link, useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from 'hooks/useAppSelector';
import { nFormatterKo } from 'utils/number-utils';
import { mapData } from './BroadcastTableCommon';
import badge1 from 'images/badge/badge1.png';
import badge4 from 'images/badge/badge4.png';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';

const BroadcastVerticalBox = () => {
  const [bi] = useAppSelector((state) => [state.broadcast]);
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  const thumbWidth = isSm ? 220 : 250;
  const thumbHeight = isSm ? 150 : 170;

  const rows = mapData(bi.all).slice(0, 10);

  return (
    <Grid>
      {/* TODO: 더보기 버튼 */}
      {/* <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
        marginBottom={4}
      ></Box> */}
      <Grid container spacing={4}>
        {rows.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              component={'a'}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              {/* 등수 별 랭킹 아이콘 */}
              {i === 0 || i == 1 || i == 2 ? (
                <Box position={'absolute'} zIndex={100}>
                  <Link href="/">
                    <img src={badge1} alt="badge1" />
                  </Link>
                </Box>
              ) : (
                <Box position={'absolute'} zIndex={100}>
                  <Link href="/">
                    <img src={badge4} alt="badge4" />
                  </Link>
                </Box>
              )}
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={4}
                display={'flex'}
                flexDirection={'column'}
                sx={{ backgroundImage: 'none' }}
              >
                {item.user.thumbs != '' ? (
                  <Link
                    target="_blank"
                    href={item.broadcastLink}
                    rel="noreferrer"
                  >
                    <CardMedia
                      image={item.user.thumbs}
                      title={item.body.title}
                      src={item.broadcastLink}
                      sx={{
                        height: { xs: 200, md: 240 },
                        position: 'relative',
                      }}
                    />
                  </Link>
                ) : (
                  <NoAccountsIcon
                    sx={{
                      width: '100%',
                      height: { xs: 200, md: 240 },
                      position: 'flex',
                      alignContent: 'center',
                      fontSize: { xs: 200, md: 240 },
                    }}
                  />
                )}

                <Box
                  component={CardContent}
                  position={'relative'}
                  // NOTE: height 에 따른 line clamp 적용임을 유의
                  sx={{
                    height: { xs: 52, md: 52 },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    boxSizing: 'inherit',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  <Typography
                    sx={{ fontFamily: 'NanumSquareAcb', fontSize: '13px' }}
                  >
                    {item.body.title}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box padding={2} display={'flex'} flexDirection={'column'}>
                  <Box marginBottom={2}>
                    <Divider />
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{}}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <Link
                        href={item.user.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Avatar src={item.user.icon} sx={{ marginRight: 1 }} />
                      </Link>
                      <Link
                        href={item.user.link}
                        underline={'hover'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography
                          color={'text.secondary'}
                          fontFamily={'NanumSquareAcb'}
                        >
                          {item.body.nick}
                        </Typography>
                      </Link>
                    </Box>
                    <Typography
                      color={'text.primary'}
                      fontSize={'13px'}
                      sx={{}}
                    >
                      {nFormatterKo(item.views, 1)}명 보는 중
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
        {/* <Grid item container justifyContent={'right'} xs={12}>
          <Button
            fullWidth
            variant={'outlined'}
            size={'large'}
            sx={{ height: 54, maxWidth: 150, justifyContent: 'space-between' }}
            endIcon={
              <Box
                component={'svg'}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </Box>
            }
          >
            더 보기
          </Button>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default BroadcastVerticalBox;
