import {
  BroadcastProvider,
  broadcastProvider,
} from '../redux/reducer/broadcast-info';

export function getProviderHex(provider: BroadcastProvider) {
  if (provider == BroadcastProvider.YOUTUBE) {
    return '#FF0000';
  } else if (provider == BroadcastProvider.SOOP) {
    return '#0546B0';
  } else if (provider == BroadcastProvider.CHZZK) {
    return '#18EA98';
  } else if (provider == BroadcastProvider.TWITCH) {
    return '#5c15c5';
  } else {
    return '#FFFFFF';
  }
}
