import { useTheme } from '@mui/material/styles';
import { useAppSelector } from 'hooks/useAppSelector';
import 'index.css';
import React, { useEffect, useState } from 'react';
import { HeaderCounterBox } from './HeaderBoxCommon';

const TotalView: React.FunctionComponent = () => {
  const [view] = useAppSelector((state) => [state.totalView]);

  const [counterValue, setCounterValue] = useState(view.totalView);
  const theme = useTheme();

  useEffect(() => {
    const newValue = view.totalView;
    const delta = newValue - counterValue;
    setCounterValue(counterValue + delta);
  }, [view.totalView]);

  return HeaderCounterBox('시청자', counterValue);
};

export default TotalView;
