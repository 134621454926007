export function insertCommas(num: number) {
  return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

export function nFormatterKo(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: '천' },
    { value: 1e4, symbol: '만' },
    { value: 1e6, symbol: '백만' },
    { value: 1e8, symbol: '억' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

export const toInt = (p: number) => Number(p.toFixed(0));

export function dateFormat(date: Date): string {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = '00';

  const monthF = month >= 10 ? month : '0' + month;
  const dayF = day >= 10 ? day : '0' + day;
  const hourF = hour >= 10 ? hour : '0' + hour;
  const minuteF = minute >= 10 ? minute : '0' + minute;

  return isNaN(month)
    ? ''
    : date.getFullYear() +
        '-' +
        monthF +
        '-' +
        dayF +
        ' ' +
        hourF +
        ':' +
        minuteF +
        ':' +
        second;
}
