import { Badge, Box, Link, styled, useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import React from 'react';
import { Broadcast, BroadcastProvider } from 'redux/reducer/broadcast-info';
import { nFormatterKo } from 'utils/number-utils';
import { getProviderHex } from 'utils/provider-utils';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  id: string;
}

export interface TableProps {
  id: number;
  user: {
    name: string;
    thumbs: string;
    logo?: string;
    link: string;
    icon: string;
  };
  body: {
    nick: string;
    title: string;
  };
  views: number;
  provider: BroadcastProvider;
  broadcastLink: string;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#1976d2',
    color: '#fff',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
}));

export function CustomTabPanel(props: TabPanelProps) {
  const { children, index, value, id, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${id}-tabpanel-${index}`}
      aria-labelledby={`${id}-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(id: string, index: number) {
  return {
    id: `${id}-tab-${index}`,
    'aria-controls': `${id}-tabpanel-${index}`,
  };
}

export function mapData(broadcast: Broadcast[]) {
  const rows = broadcast.map((value, index) => {
    return {
      id: index + 1,
      user: {
        name: value.userId,
        thumbs: value.thumbs,
        logo: '',
        link: value.link,
        icon: value.icon,
      },
      body: {
        nick: value.nick,
        title: value.title,
      },
      views: value.views,
      provider: value.provider,
      broadcastLink: value.broadcastLink,
    };
  });

  return rows;
}

export function mapTable(broadcast: Broadcast[]) {
  const rows = mapData(broadcast);
  return TableCommon(rows);
}

export const TableCommon = (rows: TableProps[]) => {
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  const thumbWidth = isSm ? 120 : 150;

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 2700 }}>
        <Table aria-label="views table">
          <TableBody>
            {rows.map((item, i) => (
              <TableRow
                key={i}
                sx={
                  {
                    // '&:last-child td, &:last-child th': {
                    //   border: 0,
                    //   height: '100',
                    // },
                    // height: '130px',
                  }
                }
              >
                <TableCell component="th" scope="row">
                  <List sx={{ p: 0, m: 0 }}>
                    <ListItem sx={{ p: 0, m: 0 }}>
                      <ListItemAvatar>
                        {item.user.thumbs != '' ? (
                          <Link
                            href={item.broadcastLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              variant="standard"
                              badgeContent={i + 1}
                              color="primary"
                              sx={{
                                width: '100',
                                height: '100%',
                                borderRadius: '50%',
                              }}
                            >
                              <Avatar
                                src={item.user.thumbs}
                                variant={'rounded'}
                                sx={{
                                  width: thumbWidth,
                                  height: 100,
                                  borderRadius: 1,
                                }}
                              />
                            </Badge>
                          </Link>
                        ) : (
                          <NoAccountsIcon
                            sx={{
                              width: thumbWidth,
                              height: 100,
                              position: 'flex',
                              alignContent: 'center',
                            }}
                          />
                        )}
                      </ListItemAvatar>
                    </ListItem>
                  </List>
                </TableCell>
                <TableCell
                  component="td"
                  sx={
                    {
                      // height: '130',
                      // display: 'flex',
                      // flexWrap: 'wrap',
                    }
                  }
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Typography
                      display="inline"
                      variant={'caption'}
                      fontWeight={700}
                      sx={{
                        bgcolor: alpha(getProviderHex(item.provider), 0.4),
                        color: theme.palette.text.primary,
                        paddingX: 0.5,
                        paddingY: 0.5,
                        marginRight: 0.3,
                        borderRadius: 2,
                        width: 50,
                        height: 30,
                        textAlign: 'center',
                      }}
                    >
                      {nFormatterKo(item.views, 1)}
                    </Typography>
                    <Link
                      href={item.user.link}
                      sx={{}}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Avatar
                        src={item.user.icon}
                        variant={'rounded'}
                        sx={{ width: 20, height: 20, borderRadius: 3 }}
                      />
                    </Link>
                    <Typography display="inline" fontWeight={700}>
                      <Link
                        href={item.user.link}
                        color="inherit"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.body.nick}
                      </Link>
                    </Typography>
                  </Box>
                  <Typography
                    color={'text.secondary'}
                    variant={'subtitle2'}
                    sx={{ width: '100%' }}
                  >
                    <Link
                      href={item.broadcastLink}
                      color="inherit"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ textDecoration: 'none' }}
                    >
                      {item.body.title}
                    </Link>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
