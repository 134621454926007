import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import { getProviderHex } from 'utils/provider-utils';
import { BroadcastProvider } from '../../../redux/reducer/broadcast-info';
import { CustomTabPanel, a11yProps, mapTable } from './BroadcastTableCommon';

const identifier = 'brdcst-1';

const BroadcastTableFirstTab = (): JSX.Element => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          aria-label="broadcast table tab"
          variant="fullWidth"
        >
          <Tab
            label="Afreeca"
            {...a11yProps(identifier, 0)}
            sx={{
              color: getProviderHex(BroadcastProvider.SOOP),
              fontWeight: 1000,
            }}
          />
          <Tab
            label="Youtube"
            {...a11yProps(identifier, 1)}
            sx={{
              color: getProviderHex(BroadcastProvider.YOUTUBE),
              fontWeight: 1000,
            }}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} id={identifier}>
        <SoopTable />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} id={identifier}>
        <YoutubeTable />
      </CustomTabPanel>
    </Box>
  );
};

// const BroadcastTable = (): JSX.Element => {
//   const [bi] = useAppSelector((state) => [state.broadcast]);
//   return mapTable(bi.all);
// };
const YoutubeTable = (): JSX.Element => {
  const [bi] = useAppSelector((state) => [state.broadcast]);
  return mapTable(bi.youtube.slice(0, 20));
};

const SoopTable = (): JSX.Element => {
  const [bi] = useAppSelector((state) => [state.broadcast]);
  return mapTable(bi.soop.slice(0, 20));
};

export default BroadcastTableFirstTab;
// export default BroadcastTable;
