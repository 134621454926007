import { ApolloClient, createHttpLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client';

const cache = new InMemoryCache();
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_DOMAIN,
});

const client = new ApolloClient({
  link: httpLink,
  cache,
});

export default client;
