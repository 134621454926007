import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SlotCounter from 'react-slot-counter';
import { insertCommas } from 'utils/number-utils';

export function HeaderCounterBox(header: string, value: number | string) {
  const settingValue = typeof value === 'number' ? insertCommas(value) : value;

  return (
    <>
      <Box
        sx={{
          marginLeft: '10px',
          paddingTop: '10px',
          fontFamily: 'NanumSquareAceb',
          fontSize: '14px',
        }}
      >
        {header}
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          fontFamily: 'NanumSquareExtraBold',
          fontWeight: '1000',
          marginTop: '6px',
        }}
      >
        <SlotCounter
          value={settingValue}
          startValue={0}
          charClassName="counter-number"
        />
      </Box>
    </>
  );
}

export function HeaderNormalBox(header: string, value: number | string) {
  const settingValue = typeof value === 'number' ? insertCommas(value) : value;
  const theme = useTheme();

  return (
    <>
      <Grid
        display={'flex'}
        sx={{
          marginLeft: '10px',
          paddingTop: '10px',
        }}
      >
        <Box>
          <LoadingButton
            loading={true}
            loadingPosition="end"
            variant="text"
            endIcon={<SaveIcon />}
            sx={{
              maxHeight: '25px',
              fontFamily: 'NanumSquareAceb',
              fontWeight: '1000',
              color: theme.palette.text.primary,
              backgroundColor: 'inherit',
            }}
          >
            {header}
          </LoadingButton>
        </Box>
      </Grid>
      <Box
        sx={{
          textAlign: 'center',
          fontFamily: 'NanumSquareAceb',
          fontWeight: '1000',
          fontSize: '25px',
          marginTop: '3px',
        }}
      >
        {settingValue}
      </Box>
    </>
  );
}
