import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useTheme } from '@mui/material/styles';
import 'index.css';
import { HeaderCounterBox } from './HeaderBoxCommon';

const TotalBroadcast: React.FunctionComponent = () => {
  const [broadcast] = useAppSelector((state) => [state.totalBroadcast]);

  const [counterValue, setCounterValue] = useState(broadcast.totalBroadcast);
  const theme = useTheme();

  useEffect(() => {
    const newValue = broadcast.totalBroadcast;
    const delta = newValue - counterValue;
    setCounterValue(counterValue + delta);
  }, [broadcast.totalBroadcast]);

  return HeaderCounterBox('방송', counterValue);
};

export default TotalBroadcast;
