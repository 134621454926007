import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BroadcastViewStateType } from './broadcast-view';
import { BroadcastViewListResponse } from 'apis/queries/broadcast-view-list';

const initialState: BroadcastViewListResponse = {
  broadcastViewListGet: [],
};

export const broadcastViewListSlice = createSlice({
  name: 'broadcastViewList',
  initialState,
  reducers: {
    setViewList: (state, action: PayloadAction<BroadcastViewListResponse>) => {
      state.broadcastViewListGet = action.payload.broadcastViewListGet;
    },
  },
});

export const { setViewList } = broadcastViewListSlice.actions;
export default broadcastViewListSlice.reducer;
