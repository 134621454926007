import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import PropTypes from 'prop-types';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { light as lightPalette, dark as darkPalette } from 'theme/palette';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from 'hooks/useAppSelector';
import BroadcastTableFirstTab from './table/BroadcastTableFirstTab';
import BroadcastTableSecondTab from './table/BroadcastTableSecondTab';
import RowStatus from './rows/RowStatus';
import RowPlatform from './rows/RowPlatform';
import RowViewAnalysis from './rows/RowViewAnalysis';
import RowTop from './rows/RowTop';
import RowBroadcast from './rows/RowBroadcast';

ChartJS.register(ArcElement, Tooltip, Legend);

export const Main: React.FunctionComponent = () => {
  const theme = useTheme();
  const palette = theme.palette.mode === 'dark' ? darkPalette : lightPalette;

  const isAboveSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  return (
    <>
      <Container maxWidth="xl">
        <RowStatus isAboveSm={isAboveSm} />
        <RowPlatform isAboveSm={isAboveSm} />
        <RowViewAnalysis isAboveSm={isAboveSm} />
        <RowTop isAboveSm={isAboveSm} />
        <RowBroadcast isAboveSm={isAboveSm} />
      </Container>
    </>
  );
};

export default Main;
