import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { lazy, useState, useEffect } from 'react';
import './App.css';
import Preparing from './components/Preparing';
import Main from './components/main';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, PaletteMode } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Loader from './common/Loader';
import { amber, deepOrange, grey } from '@mui/material/colors';
import getTheme from 'theme';

// const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export const useDarkMode = (): [string, () => void, boolean] => {
  const [themeMode, setTheme] = useState('dark');
  const [mountedComponent, setMountedComponent] = useState(false);

  const setMode = (mode: string) => {
    try {
      window.localStorage.setItem('themeMode', mode);
    } catch {
      /* do nothing */
    }

    setTheme(mode);
  };

  const themeToggler = (): void => {
    themeMode === 'light' ? setMode('dark') : setMode('light');
  };

  useEffect(() => {
    try {
      const localTheme = window.localStorage.getItem('themeMode');
      localTheme ? setTheme(localTheme) : setMode('dark');
      // dark 강제화
      if (localTheme == 'light') {
        setMode('dark');
        setTheme('dark');
      }
    } catch {
      setMode('light');
    }

    setMountedComponent(true);
  }, []);

  return [themeMode, themeToggler, mountedComponent];
};

const DefaultLayout = lazy(() => import('./layout/DefaultLayout'));

function App() {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const [themeMode, themeToggler, _] = useDarkMode();
  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <ThemeProvider theme={getTheme(themeMode, themeToggler)}>
        <CssBaseline />
        <HelmetProvider>
          <Helmet>
            <title>방방♪♪</title>
            <meta
              name="viewport"
              property="viewport"
              content="initial-scale=1, width=device-width"
            />
          </Helmet>
          <Routes>
            <Route element={<DefaultLayout />}>
              <Route index element={<Main />} />
              <Route path="/streamer" element={<Preparing />} />
              <Route path="/calendar" element={<Preparing />} />
              <Route path="/vs-today" element={<Preparing />} />
              <Route path="/vs-history" element={<Preparing />} />
              <Route path="/viewer" element={<Preparing />} />
              <Route path="*" element={<Preparing />} />
            </Route>
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}

            {/* <Route path="/dashboard2" element={<Dashboard2 />} /> */}
            {/* <Route path="*" element={<Dashboard />} /> */}
          </Routes>
        </HelmetProvider>
      </ThemeProvider>
    </React.Fragment>
  );

  // return (
  //   <>
  //     <div className="text-3xl text-blue-500">hello world</div>
  //     <h1 className="text-3xl font-bold underline">Hello world!</h1>
  //   </>
  // );
}

export default App;
