import React from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { light as lightPalette, dark as darkPalette } from 'theme/palette';
import ApexLineChart from 'components/main/chart/ApexLineChart';

interface Props {
  isAboveSm: boolean;
}

{
  /* 세번째 row. 그래프 정보 */
}
const RowViewAnalysis = ({ isAboveSm }: Props) => {
  const theme = useTheme();
  const palette = theme.palette.mode === 'dark' ? darkPalette : lightPalette;
  return (
    <>
      <Grid
        container
        spacing={1}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          paddingTop: '1.25rem',
          backgroundColor: 'inherit',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <Grid
          container
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{
            backgroundColor: 'inherit',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            fontFamily={'NanumSquareAceb'}
            variant={'h6'}
            sx={{
              marginLeft: '10px',
              paddingTop: '10px',
            }}
          >
            시청자 수 분석
          </Typography>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: palette.background.level1,
              paddingInline: '20px',
              borderRadius: '5px',
            }}
          >
            {/* TODO: Doughnut 데이터에 의해 ApexLineChart 도 실시간 갱신되는 문제가 있음 */}
            <ApexLineChart />
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}></Grid>
      </Grid>
    </>
  );
};

export default RowViewAnalysis;
