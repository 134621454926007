import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface VisitorStateType {
  curVisitor: number;
}

const initialState: VisitorStateType = {
  curVisitor: 0,
};

export const visitorSlice = createSlice({
  name: 'curVisitor',
  initialState,
  reducers: {
    setting: (state, action: PayloadAction<number>) => {
      state.curVisitor = action.payload;
    },
  },
});

export const { setting } = visitorSlice.actions;
export default visitorSlice.reducer;
