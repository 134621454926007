import React from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { Doughnut } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { insertCommas } from 'utils/number-utils';
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

Chart.register(ChartDataLabels);

const ChartJsDoughnut = () => {
  const [bv] = useAppSelector((state) => [state.broadcastView]);
  const theme = useTheme();
  const all = bv.youtube + bv.chzzk + bv.soop + bv.twitch + 1;

  const isAboveMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <>
      <Doughnut
        data={{
          labels: ['Youtube', 'Afreeca', 'Chzzk', 'Twitch'],
          datasets: [
            {
              label: '시청자 수',
              data: [bv.youtube, bv.soop, bv.chzzk, bv.twitch],
              backgroundColor: [
                'rgba(255, 0, 0, 0.7)',
                'rgba(5, 70, 176, 0.7)',
                'rgba(24, 234, 152, 0.7)',
                'rgba(92, 21, 197, 0.7)',
              ],
              borderColor: [
                'rgba(255, 0, 0, 1)',
                'rgba(5, 70, 176, 1)',
                'rgba(24, 234, 152, 1)',
                'rgba(92, 21, 197, 1)',
              ],
              borderWidth: 3,
            },
          ],
        }}
        options={{
          color: '#FFF',
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1,
          circumference: 360,
          offset: 0,
          radius: '100%',
          animation: {
            animateRotate: false,
            animateScale: false,
          },
          interaction: {
            mode: 'nearest',
            // mode: 'dataset',
            intersect: true,
          },
          plugins: {
            datalabels: {
              display: true,
              color: theme.palette.text.primary,
              font: {
                size: 12,
                family: 'NanumSquareAceb',
                weight: 500,
              },
              formatter(value, context) {
                const parsed =
                  typeof value === 'number'
                    ? `${insertCommas(value)} (${((value / all) * 100).toFixed(
                        1,
                      )}%)`
                    : 0;
                return parsed != 0 ? parsed : ''; // 0은 아예 보이지 않도록 한다
              },
            },
            tooltip: {
              enabled: true,
              intersect: true,
            },
            legend: {
              display: true,
              labels: {
                font: {
                  family: 'NanumSquareAceb',
                },
              },
              position: isAboveMd || isXs ? 'right' : 'bottom',
            },
          },
        }}
      />
    </>
  );
};

export default ChartJsDoughnut;
