import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface StateType {
  totalBroadcast: number;
}

const initialState: StateType = {
  totalBroadcast: 0,
};

export const totalBroadcastSlice = createSlice({
  name: 'totalBroadcast',
  initialState,
  reducers: {
    setting: (state, action: PayloadAction<number>) => {
      state.totalBroadcast = action.payload;
    },
  },
});

export const { setting } = totalBroadcastSlice.actions;
export default totalBroadcastSlice.reducer;
