import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useAppSelector } from 'hooks/useAppSelector';

const Preparing: React.FunctionComponent = () => {
  const [visitor] = useAppSelector((state) => [state.curVisitor]);

  return (
    <>
      <Typography variant="h3" gutterBottom>
        준비중입니다! {visitor.curVisitor}
      </Typography>
    </>
  );
};

export default Preparing;
