import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface StateType {
  totalView: number;
}

const initialState: StateType = {
  totalView: 0,
};

export const totalViewSlice = createSlice({
  name: 'totalView',
  initialState,
  reducers: {
    setting: (state, action: PayloadAction<number>) => {
      state.totalView = action.payload;
    },
  },
});

export const { setting } = totalViewSlice.actions;
export default totalViewSlice.reducer;
