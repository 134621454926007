import { configureStore } from '@reduxjs/toolkit';

import curVisitorReducer from '../reducer/cur-visitor';
import totalViewReducer from '../reducer/total-view';
import totalBroadcastReducer from '../reducer/total-broadcast';
import broadcastReducer from '../reducer/broadcast-info';
import broadcastViewReducer from '../reducer/broadcast-view';
import broadcastViewListReducer from '../reducer/broadcast-view-list';

export const store = configureStore({
  reducer: {
    curVisitor: curVisitorReducer,
    totalView: totalViewReducer,
    totalBroadcast: totalBroadcastReducer,
    broadcast: broadcastReducer,
    broadcastView: broadcastViewReducer,
    broadcastViewList: broadcastViewListReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
