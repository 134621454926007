import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export const broadcastProvider = {
  UNKNOWN: 0,
  YOUTUBE: 1,
  CHZZK: 2,
  SOOP: 3,
  TWITCH: 4,
} as const;

export enum BroadcastProvider {
  UNKNOWN = 'UNKNOWN',
  YOUTUBE = 'YOUTUBE',
  CHZZK = 'CHZZK',
  SOOP = 'SOOP',
  TWITCH = 'TWITCH',
}

// export type BroadcastProvider =
// (typeof broadcastProvider)[keyof typeof broadcastProvider];

// export type BroadcastProviderKey = keyof typeof broadcastProvider;

export function getBroadcastProvider(code: BroadcastProvider) {
  console.log(code);
}

export interface Broadcast {
  thumbs: string;
  userId: string;
  nick: string;
  views: number;
  title: string;
  provider: BroadcastProvider;
  broadcastLink: string;
  link: string;
  icon: string;
}

export interface BroadcastStateType {
  ts: number;
  tsStr: string;
  broadcast: Broadcast[];
  all: Broadcast[];
  youtube: Broadcast[];
  chzzk: Broadcast[];
  soop: Broadcast[];
  twitch: Broadcast[];
}

const initialState: BroadcastStateType = {
  ts: 0,
  tsStr: '',
  broadcast: [],
  all: [],
  youtube: [],
  chzzk: [],
  soop: [],
  twitch: [],
};

export const broadcastSlice = createSlice({
  name: 'broadcast',
  initialState,
  reducers: {
    setting: (state, action: PayloadAction<BroadcastStateType>) => {
      state.ts = action.payload.ts;
      state.tsStr = action.payload.tsStr;
      state.broadcast = action.payload.broadcast;
      state.all = action.payload.all;
      state.youtube = action.payload.youtube;
      state.chzzk = action.payload.chzzk;
      state.soop = action.payload.soop;
      state.twitch = action.payload.twitch;
    },
  },
});

export const { setting } = broadcastSlice.actions;
export default broadcastSlice.reducer;
