import React from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { light as lightPalette, dark as darkPalette } from 'theme/palette';
import BroadcastTableFirstTab from '../table/BroadcastTableFirstTab';
import BroadcastTableSecondTab from '../table/BroadcastTableSecondTab';
import BroadcastTableMobileTab from '../table/BroadcastTableMobileTab';

interface Props {
  isAboveSm: boolean;
}

{
  /* 다섯번째 row. provider 별 정보 */
}
const RowBroadcast = ({ isAboveSm }: Props) => {
  const theme = useTheme();
  const palette = theme.palette.mode === 'dark' ? darkPalette : lightPalette;

  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  return (
    <>
      <Grid
        container
        spacing={1}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          marginTop: '0.5rem',
          marginBottom: '1.25rem',
          borderRadius: '1rem',
          paddingTop: '1.25rem',
          backgroundColor: 'inherit',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <Grid
          container
          xs={12}
          sx={{
            backgroundColor: 'inherit',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            fontFamily={'NanumSquareAceb'}
            variant={'h6'}
            sx={{
              marginLeft: '10px',
              paddingTop: '10px',
            }}
          >
            실시간 방송 목록
          </Typography>
        </Grid>
        {isMobile == false ? (
          <>
            <Grid
              container
              lg={6}
              md={6}
              sm={6}
              xs={12}
              sx={{
                backgroundColor: 'inherit',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  backgroundColor: palette.background.level1,
                  paddingInline: '20px',
                  borderRadius: '5px',
                }}
              >
                <BroadcastTableFirstTab />
              </Grid>
            </Grid>
            <Grid
              container
              lg={6}
              md={6}
              sm={6}
              xs={12}
              sx={{
                backgroundColor: 'inherit',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  backgroundColor: palette.background.level1,
                  paddingInline: '20px',
                  borderRadius: '5px',
                }}
              >
                <BroadcastTableSecondTab />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: 'inherit',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                backgroundColor: palette.background.level1,
                paddingInline: '20px',
                borderRadius: '5px',
              }}
            >
              <BroadcastTableMobileTab />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default RowBroadcast;
