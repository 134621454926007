import React from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { light as lightPalette, dark as darkPalette } from 'theme/palette';
import UpdateTime from 'components/layout/header/UpdateTime';
import TotalView from 'components/layout/header/TotalView';
import TotalBroadcast from 'components/layout/header/TotalBroadcast';

interface Props {
  isAboveSm: boolean;
}

{
  /* 첫번째 row. 전역 정보 */
}
const RowStatus = ({ isAboveSm }: Props) => {
  const theme = useTheme();
  const palette = theme.palette.mode === 'dark' ? darkPalette : lightPalette;
  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          paddingBottom: isAboveSm ? '0.5rem' : '0rem',
          backgroundColor: palette.background.default,
        }}
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            backgroundColor: 'inherit',
            height: isAboveSm ? '7rem' : '13rem',
            display: 'flex',
          }}
        >
          <Grid
            item
            lg={4}
            md={4}
            sm={4}
            xs={12}
            sx={{
              marginRight: '0.5rem',
              borderRadius: '5px',
              backgroundColor: palette.background.level1,
            }}
          >
            <UpdateTime />
          </Grid>

          <Grid
            item
            lg={3}
            md={3}
            sm={3}
            xs={5.8}
            sx={{
              marginTop: isAboveSm ? '0rem' : '1rem',
              marginRight: '0.5rem',
              borderRadius: '5px',
              backgroundColor: palette.background.level1,
            }}
          >
            <TotalBroadcast />
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={3}
            xs={5.8}
            sx={{
              marginTop: isAboveSm ? '0rem' : '1rem',
              borderRadius: '5px',
              backgroundColor: palette.background.level1,
            }}
          >
            <TotalView />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RowStatus;
