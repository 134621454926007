import React from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { light as lightPalette, dark as darkPalette } from 'theme/palette';
import BroadcastVerticalBox from '../table/BroadcastVerticalBox';

interface Props {
  isAboveSm: boolean;
}

{
  /* 네번째 row. Top20 정보 */
}
const RowTop = ({ isAboveSm }: Props) => {
  const theme = useTheme();
  const palette = theme.palette.mode === 'dark' ? darkPalette : lightPalette;

  return (
    <>
      <Grid
        container
        spacing={1}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          paddingTop: '1.25rem',
          backgroundColor: 'inherit',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <Grid
          container
          xs={12}
          sx={{
            backgroundColor: 'inherit',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            fontFamily={'NanumSquareAceb'}
            variant={'h6'}
            sx={{
              marginLeft: '10px',
              paddingTop: '10px',
            }}
          >
            시청자 Top10
          </Typography>
        </Grid>
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            backgroundColor: 'inherit',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: palette.background.level1,
              padding: '10px',
              borderRadius: '5px',
            }}
          >
            <BroadcastVerticalBox />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RowTop;
