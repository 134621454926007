import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from 'hooks/useAppSelector';
import 'index.css';
import React, { useEffect, useState } from 'react';
import { HeaderNormalBox } from './HeaderBoxCommon';
import { dateFormat } from 'utils/number-utils';

const UpdateTime: React.FunctionComponent = () => {
  const [view] = useAppSelector((state) => [state.totalView]);
  const [bi] = useAppSelector((state) => [state.broadcast]);

  Date.parse(bi.tsStr);
  const [counterValue, setCounterValue] = useState('0');
  const theme = useTheme();

  useEffect(() => {
    setCounterValue(bi.tsStr);
  }, [bi.tsStr]);

  const settingValue =
    counterValue === '0' ? '' : dateFormat(new Date(counterValue));
  return HeaderNormalBox('업데이트', settingValue);
};

export default UpdateTime;
